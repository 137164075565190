import React, { FC, useEffect, useState } from 'react';
import Layout from '@/common/ui/template/Layout';

import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import Footer from '@/newcar/ui/common/organism/Footer';
import SEO from '@/newcar/ui/information/organism/SEO';
import { Dayjs } from 'dayjs';
import { safeNow, jst } from '@/common/util/datetime';
import useAllNewCarInformationQuery from '@/newcar/ui/information/query/useAllNewCarInformationQuery';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/pages/information/index.module.styl';
import { Link } from 'gatsby';

interface State {
  now?: Dayjs
}

const Page: FC = () => {
  const [state, setState] = useState<State>({});

  useEffect(() => {
    (async (): Promise<void> => {
      setState({
        ...state,
        now: await safeNow(),
      });
    })();
  }, [setState]);

  const getAllInformationResult = useAllNewCarInformationQuery();
  return (
    <Layout>
      {/* ヘッダー */}
      <Header />
      <SEO />
      <Breadcrumb
        items={[
          { to: NEWCAR_DEFINE.PATH.TOP, label: '新車TOP' },
          { label: 'お知らせ一覧' },
        ]}
      />
      <main className={styles.hasBackgroundLightgrey}>
        <div className="container">
          <section>
            <div className={styles.innerContainer}>
              <h2 className="is-margin-bottom-5">
                お知らせ一覧
              </h2>
              <div
                className={`is-size-6-tablet is-size-4-mobile has-background-white has-background-white ${styles.contentsPaddingMargin} ${styles.contentsBorder}`}
              >
                <ul>
                  {getAllInformationResult.allMicrocmsNewcarInformation.edges.map(
                    ({ node }, i) => (
                      <li key={node.id}>
                        <a
                          href={node.url ?? ''}
                          className={`${styles.informationLists} ${
                            styles.linkLive
                          } ${
                            i === getAllInformationResult.allMicrocmsNewcarInformation.edges.length - 1 ? '' : styles.contentsBorderBottom
                          }`}
                        >
                          <p>
                            {node.title ?? ''}
                            <br className="is-hidden-tablet" />
                            <span>{jst(node.date).format('YY.MM.DD')}</span>
                          </p>
                        </a>
                      </li>
                    ),
                  )}
                </ul>
              </div>
              <div className="has-text-centered">
                <Link
                  className="button radius-most is-large is-fixed-width-button is-margin-top-7 is-margin-bottom-7 is-size-5"
                  to={NEWCAR_DEFINE.PATH.TOP}
                >
                  定額カルモくんトップページへ
                  <span
                    className={`icon-arrow has-background-black ${styles.topLinkIconArrow} `}
                  />
                </Link>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default Page;
